import { TickBold } from 'shared/components/icons';
import { ReferralContainer } from '../css';
import { useEffect, useState } from 'react';
import { GETReferralRewards, IReferralRewardResponse, POSTClaimReferralRewards } from 'api/v1/referral-reward';
import numberUtils from 'shared/utils/number';
import Loader from 'shared/components/loader';
import { GETProfileAPI } from 'api/v2/profile';
import { changeProfileOverview } from 'store/reducers/profile';
import { useDispatch } from 'react-redux';

const Referral = () => {
	const [data, setData] = useState<IReferralRewardResponse[] | null>(null);
	const dispatch = useDispatch();

	const updateData = () => {
		setData(null);
		GETReferralRewards().then(res => {
			setData(res);
		});
	};

	useEffect(() => {
		updateData();
	}, []);

	const onClaimClickHandler = (id: number) => {
		POSTClaimReferralRewards(id).then(() => {
			GETProfileAPI().then(res => {
				dispatch(changeProfileOverview(res));
			});
			updateData();
		});
	};
	return (
		<ReferralContainer>
			<div className='background'>
				{data &&
					data.map((item, index) => (
						<div
							className={`referral_card`}
							key={`referral_card_${index}`}
						>
							<div className='img_container'>
								<img src={`/img/pineye/p_level_${index + 1}.webp`} alt='' draggable={false} />
							</div>

							<div className='title'>
								<p>Invite {numberUtils.formatPriceWithDecimals(item.friendsCount)} friend</p>
								{item.inprogress && !item.canClaim && (
									<div className='progress'>
										{numberUtils.formatPriceWithDecimals(item.yourFriendsCount)}/
										{numberUtils.formatPriceWithDecimals(item.friendsCount)}
									</div>
								)}
							</div>
							<div className='value'>
								<div className={`${item.hasClaimed?'value_container checked':' value_container'}`}>
									<img src='/img/dollar.webp' alt='' draggable={false} />+
									{numberUtils.formatPriceWithDecimals(item.bonus)}
									{item.hasClaimed && <TickBold className='green' />}
								</div>
								{item.canClaim && (
									<div
										className='claim_btn'
										onClick={() => {
											onClaimClickHandler(item.id);
										}}
									>
										Claim
									</div>
								)}
							</div>
							

							{/* {item.inprogress && !item.canClaim && (
								<>
									
									<div className='progress_container'>
										<div className='progress_empty'>
											<div
												className='filled'
												style={{ width: `${(item.yourFriendsCount / item.friendsCount) * 100}%` }}
											></div>
										</div>
									</div>
								</>
							)} */}
						</div>
					))}
				{!data && <Loader />}
			</div>
		</ReferralContainer>
	);
};

export default Referral;
